import { solidGateActions } from './solidGateActions';
import { fetchStripePaymentIntentRequest } from './api';

const initialState = {
  solidGatePaymentIntent: {
    solidgate_data: {
      merchant_id: null,
      signature: null,
      payment_intent: null,
    },
  },
  paymentMethod: null,
  amount: null,
  stripePaymentData: null,

  // Crucial properties
  hasPaymentIntent: false,
  expiresAt: null,
  subtotal: null,
  totalAmount: null,
  transactionFee: null,
  tipsAmount: null,
  discountAmount: null,
  couponCode: null,
  uniqueOrderNumber: null,
  smallOrderFee: null,
  uuid: null,
  error: false,
  errorMessage: null,
};

export default {
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setPaymentMethod(state, paymentMethod) {
      state.paymentMethod = paymentMethod;
    },
    setAmount(state, amount) {
      state.amount = amount;
    },
    setSolidGatePaymentIntent(state, solidGatePaymentIntent) {
      state.solidGatePaymentIntent = solidGatePaymentIntent;
    },
    setStripePaymentData(state, stripePaymentData) {
      state.stripePaymentData = stripePaymentData;
    },
    setError(state, { error, errorMessage } = {}) {
      state.error = error;
      state.errorMessage = errorMessage;
    },
    setCrucialProperties(
      state,
      {
        hasPaymentIntent,
        expiresAt,
        subtotal,
        totalAmount,
        transactionFee,
        tipsAmount,
        discountAmount,
        couponCode,
        uniqueOrderNumber,
        smallOrderFee,
        uuid,
        error,
      } = {}
    ) {
      state.hasPaymentIntent = hasPaymentIntent;
      state.expiresAt = expiresAt;
      state.subtotal = subtotal;
      state.totalAmount = totalAmount;
      state.transactionFee = transactionFee;
      state.tipsAmount = tipsAmount;
      state.discountAmount = discountAmount;
      state.couponCode = couponCode;
      state.uniqueOrderNumber = uniqueOrderNumber;
      state.smallOrderFee = smallOrderFee;
      state.uuid = uuid;
      state.error = error;
    },
  },
  actions: {
    resetPaymentState({ commit }) {
      commit('resetState');
    },
    ...solidGateActions,
    setPaymentMethod({ commit }, paymentMethod) {
      commit('setPaymentMethod', paymentMethod);
    },
    setAmount({ commit }, amount) {
      commit('setAmount', amount);
    },
    async generateStripePaymentIntent(
      { commit, getters },
      {
        personnelId = null,
        tips = null,
        payForSelectedItems = false,
        order = null,
      } = {}
    ) {
      try {
        const session = getters.session;
        const paymentRequest = await fetchStripePaymentIntentRequest({
          session,
          order,
          personnelId,
          tips,
          payForSelectedItems,
        });
        if (paymentRequest.data.total) {
          commit('setStripePaymentData', paymentRequest.data);
          commit('setCrucialProperties', paymentRequest.data);
        } else {
          this.error = true;
        }
        return;
      } catch (error) {
        const matchErrorMessages = {
          'Order is already paid': 'Order is already paid',
          'Order already has payments of other type':
            'Order already has payments of other type',
        };
        const errorMessage = matchErrorMessages[error.response?.data?.message];

        if (errorMessage) {
          commit('setError', {
            error: true,
            errorMessage,
          });
        }
      }
    },
  },
  getters: {
    paymentMethod: state => state.paymentMethod,
    solidGatePaymentIntent: state => state.solidGatePaymentIntent,
  },
};
