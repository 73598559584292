import { generateSolidGatePaymentIntentRequest } from './api';

export const solidGateActions = {
  async generateSolidGatePaymentIntent(
    { commit },
    { session, amount, currency, paymentMethod, customer, order }
  ) {
    try {
      const response = await generateSolidGatePaymentIntentRequest({
        session,
        amount,
        currency,
        paymentMethod,
        customer,
        order,
      });
      commit('setSolidGatePaymentIntent', response.data);
    } catch (error) {
      console.log('Error: ', error);
      commit('setError', error);
    }
  },
};
