<template>
  <v-bottom-sheet v-model="sheet" inset :max-width="maxAppWidth">
    <v-sheet class="px-4 pt-10 pb-8" rounded="xl b-0">
      <div class="d-flex justify-center">
        <lottie-vue-player
          src="/assets/order-status-animations/failed-payment.json"
          style="width: 100px; height:100px"
          autoplay
          :loop="false"
        />
      </div>
      <div class="tp-text-body mb-6 mt-8" style="white-space: pre-line;">
        <div class="tp-text-title-bold mb-3">
          {{ errorContent.title }}
        </div>
        {{ errorContent.content }}
      </div>
      <Button
        dark
        no-shadow
        data-cy="payment-error-bottom-sheet"
        @click="onCtaPress"
      >
        {{ errorContent.cta }}
      </Button>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  components: {
    Button,
  },
  data: () => ({
    sheet: false,
    declineCode: null,
  }),
  computed: {
    errorContent() {
      const PAYMENT_ERRORS = {
        generic: {
          title: this.$t('components.paymentBottomSheetError.generic.title'),
          content: this.$t(
            'components.paymentBottomSheetError.generic.content'
          ),
          cta: this.$t('components.paymentBottomSheetError.generic.cta'),
        },
        insufficient_funds: {
          title: this.$t(
            'components.paymentBottomSheetError.insufficientFunds.title'
          ),
          content: this.$t(
            'components.paymentBottomSheetError.insufficientFunds.content'
          ),
          cta: this.$t(
            'components.paymentBottomSheetError.insufficientFunds.cta'
          ),
        },
      };

      return PAYMENT_ERRORS[this.declineCode] || PAYMENT_ERRORS.generic;
    },
  },
  methods: {
    close() {
      this.sheet = false;
    },
    open(error) {
      const declineCode = error?.decline_code;

      this.declineCode = declineCode;
      this.sheet = true;
    },
    async onCtaPress() {
      this.declineCode === null;
      this.$emit('paymentErrorBottomSheetCtaPress');
      this.close();
    },
  },
};
</script>
