<template>
  <div id="wallet-button" />
</template>

<script>
export default {
  name: 'SolidGateWallet',
  data: () => ({
    form: null,
  }),
  computed: {
    solidGatePaymentIntent() {
      return this.$store.getters.solidGatePaymentIntent;
    },
    merchantData() {
      const { solidgate_data } = this.solidGatePaymentIntent;

      return {
        merchant: solidgate_data.merchant_id,
        signature: solidgate_data.signature,
        paymentIntent: solidgate_data.payment_intent,
      };
    },
    isAndroid() {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.includes('android');
    },
    isIOS() {
      const isIOS = /iPad|iPhone/.test(navigator.userAgent);
      const isAppleDevice = navigator.userAgent.includes('Macintosh');

      return isIOS || isAppleDevice;
    },
    isDev() {
      // const env = process.env.NODE_ENV;
      // return env === 'development';
      return true;
    },
    showApplePay() {
      if (this.isDev) {
        return true;
      }
      return !this.isAndroid;
    },
    showGooglePay() {
      if (this.isDev) {
        return true;
      }
      return !this.isIOS;
    },
  },
  watch: {
    solidGatePaymentIntent() {
      if (this.solidGatePaymentIntent) {
        const { solidgate_data } = this.solidGatePaymentIntent;

        if (solidgate_data) {
          this.initWallet();
        }
      }
    },
  },
  async mounted() {
    this.initWallet();
  },
  destroyed() {
    if (this.form) {
      console.log('🚀 ~ destroyed ~ this.form: >>>>>', this.form);
      this.form.unsubscribeAll();
      this.form = null;
    }
  },
  methods: {
    initWallet() {
      const merchantId = this.merchantData.merchant;
      console.log('🚀 ~ initWallet ~ merchantId:', merchantId);
      if (!merchantId) {
        console.warn('Merchant ID is not available');
        return;
      }

      const walletData = {
        merchantData: this.merchantData,
        iframeParams: {
          width: '100%',
          containerId: 'wallet-button',
        },
        formParams: {
          buttonType: 'default',
          submitButtonText: 'Pay the Piper',
          isCardHolderVisible: true,
          hideCvvNumbers: true,
          headerText: 'Enter your debit or credit card details (from merchant)',
          titleText: 'Card info (from merchant)',
          formTypeClass: 'default',
          googleFontLink:
            '//fonts.googleapis.com/css2?family=DM+Sans:ital@1&display=swap',
          autoFocus: false,
        },
        styles: {
          submit_button: {
            'background-color': 'red',
            'font-size': '16px',
            'font-weight': 'bold',
            ':hover': {
              'background-color': 'green',
            },
            form_body: {
              'font-family': 'DM Sans',
            },
          },
        },
        googlePayButtonParams: {
          enabled: this.showGooglePay,
          buttonType: 'pay',
          containerId: 'wallet-button',
          buttonColor: 'black',
          color: 'black',
        },
        applePayButtonParams: {
          enabled: this.showApplePay,
          containerId: 'wallet-button',
          type: 'pay',
          buttonColor: 'black',
          color: 'black',
          integrationType: 'js',
        },
      };
      // eslint-disable-next-line no-undef
      const walletForm = PaymentFormSdk.init(walletData);

      walletForm.on('success', data => {
        console.log('🚀 ~ initWallet ~ data:', data);
        this.$emit('on-success', data);
      });
      walletForm.on('fail', error => {
        // eslint-disable-next-line no-debugger
        debugger;

        this.$emit('on-error', error);
      });
      walletForm.on('error', error => {
        console.log('🚀 ~ initWallet ~ error:', error);
        this.$emit('on-error', error);
      });
      walletForm.on('submit', data => {
        console.log('🚀 ~ initWallet ~ data:', data);
        this.$emit('on-submit', data);
      });
      walletForm.on('mounted', e => {
        console.log('🚀 ~ initWallet ~ e:', e);
      });

      this.form = walletForm;
    },
  },
};
</script>

<style scoped>
.card-form-container {
  /* iframe inside no border */
  iframe {
    border: none !important;
  }
}
</style>
