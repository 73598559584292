import Vue from 'vue';

export const generateSolidGatePaymentIntentRequest = async ({
  session,
  order,
  personnelId,
  tips,
  payForSelectedItems,
}) => {
  return Vue.axios.post(`/payments/solidgate/payment-intent`, {
    session,
    order,
    personnel_id: personnelId,
    tips: tips * 100,
    pay_for_selected_items: payForSelectedItems,
  });
};

export const fetchStripePaymentIntentRequest = async ({
  session,
  order,
  personnelId,
  tips,
  payForSelectedItems,
}) => {
  return Vue.axios.post(`/payments/stripe/payment-intent`, {
    session,
    order,
    personnel_id: personnelId,
    tips: tips * 100,
    pay_for_selected_items: payForSelectedItems,
  });
};
