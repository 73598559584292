<template>
  <div id="card-form-container" class="card-form-container" />
</template>

<script>
export default {
  name: 'SolidGate',
  data: () => ({
    form: null,
  }),
  computed: {
    solidGatePaymentIntent() {
      return this.$store.getters.solidGatePaymentIntent;
    },
    merchantData() {
      const { solidgate_data } = this.solidGatePaymentIntent;

      return {
        merchant: solidgate_data.merchant_id,
        signature: solidgate_data.signature,
        paymentIntent: solidgate_data.payment_intent,
      };
    },
    isAndroid() {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.includes('android');
    },
    isIOS() {
      const isIOS = /iPad|iPhone/.test(navigator.userAgent);
      const isAppleDevice = navigator.userAgent.includes('Macintosh');

      return isIOS || isAppleDevice;
    },
    isDev() {
      // const env = process.env.NODE_ENV;
      // return env === 'development';
      return true;
    },
    showApplePay() {
      if (this.isDev) {
        return true;
      }
      return !this.isAndroid;
    },
    showGooglePay() {
      if (this.isDev) {
        return true;
      }
      return !this.isIOS;
    },
  },
  watch: {
    solidGatePaymentIntent(newVal) {
      if (newVal) {
        const { solidgate_data } = this.newVal;

        if (solidgate_data) {
          this.initCard();
        }
      }
    },
  },
  async mounted() {
    this.initCard();
  },
  destroyed() {
    if (this.form) {
      console.log('🚀 ~ destroyed ~ this.form: --->', this.form);
      this.form.destroy();
    }
  },
  methods: {
    initCard() {
      const data = {
        merchantData: this.merchantData,
        iframeParams: {
          width: '100%',
          containerId: 'card-form-container',
        },
        formParams: {
          enabled: true,
          googleFontLink:
            'https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"',
        },
        googlePayButtonParams: {
          enabled: false,
        },
        applePayButtonParams: {
          enabled: false,
        },
        styles: {
          submit_button: {
            display: 'none',
          },
        },
      };
      // eslint-disable-next-line no-undef
      const form = PaymentFormSdk.init(data);

      form.on('success', data => {
        this.$emit('on-success', data);
      });
      form.on('fail', error => {
        this.$emit('on-error', error);
      });
      form.on('error', error => {
        this.$emit('on-error', error);
      });

      this.form = form;
    },
    submit() {
      this.form?.submit();
    },
  },
};
</script>

<style scoped>
.card-form-container {
  /* iframe inside no border */
  iframe {
    border: none !important;
  }
}
</style>
